
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

@Component
export default class ADatePicker extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public rules?: any[];
  @Prop({
    // Above 18 years
    default: new Date(new Date().getFullYear() - 18, 12, 31)
      .toISOString()
      .substring(0, 10),
  })
  public max?: string;

  public activePicker = "";
  public date = "";
  public menu = false;

  @Emit("input")
  save(date: Date) {
    return date;
  }

  @Watch("menu")
  menuWatch(val: boolean) {
    val && setTimeout(() => (this.activePicker = "YEAR"));
  }
}
