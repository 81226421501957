
import { Component, Emit, Model, Prop, Vue } from "vue-property-decorator";

type CroppieType = {
  rotate: (angle: number) => void;
  bind: (data: { url: string; zoom?: boolean }) => void;
  setZoom: (data: number) => void;
  result: (
    options: { format: string; square: boolean },
    handler: (output: string) => void
  ) => void;
};

type ImageType = {
  click: () => void;
  files: File[];
};

@Component
export default class PhotoSelector extends Vue {
  $refs!: Vue["$refs"] & {
    croppieRef: CroppieType;
    imagePhoto: ImageType;
  };

  @Prop({ required: false, type: Number, default: 100 }) public width!: number;
  @Prop({ required: false, type: Number, default: 100 }) public height!: number;
  @Prop({ required: false, type: String, default: "square" })
  public type!: string;

  @Model("change", {
    type: String,
    default: require("@/assets/images/default-image.png"),
  })
  readonly resource!: string;

  public logo: string | null = null;

  private imageChanged = false;

  private config = {
    enforceBoundary: true,
    enableResize: true,
    enableOrientation: true,
  };

  async crop(): Promise<string | undefined> {
    let options = {
      format: "png",
      square: true,
    };

    return await new Promise((resolve, _) => {
      try {
        if (!this.imageChanged) throw "No Image Selected";
        this.$refs.croppieRef.result(options, (output) => {
          resolve(output);
        });
      } catch (e) {
        resolve(undefined);
      }
    });
  }

  @Emit()
  onError(message: unknown): unknown {
    return message;
  }

  result(output: string): void {
    this.logo = output;
  }

  rotate(rotationAngle: number): void {
    this.$refs.croppieRef.rotate(rotationAngle);
  }

  OnFileChanged(): void {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      const files = this.$refs.imagePhoto.files;
      if (!files.length) {
        this.$snack?.error("Please select a file.");
        return;
      }
      const file = URL.createObjectURL(files[0]);
      this.$refs.croppieRef.bind({
        url: file,
        ...this.config,
      });

      this.imageChanged = true;
    } else {
      this.$snack?.error("File Api not supported on this browser.");
    }
  }

  selectFile(): void {
    this.$refs.imagePhoto.click();
  }

  async mounted(): Promise<void> {
    this.$nextTick(async () => {
      await this.$refs.croppieRef.bind({
        url: this.resource,
        ...this.config,
      });
    });
  }
}
